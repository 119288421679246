body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  width: 100%;
  height: 100%;
  background: burlywood; }

* {
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1 {
  margin-left: 10px;
  line-height: 1; }

::-webkit-scrollbar {
  display: none; }

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: transparent; }

.disclaimer {
  position: absolute;
  z-index: 100;
  top: 0px;
  right: 20px;
  color: red;
  font-size: 7px; }

.draw-interface {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 2, 0.5);
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 1; }

.draw-interface--hide {
  opacity: 0;
  pointer-events: none; }

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  background: #fff;
  border-bottom: 2px solid transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 150px;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.draw-button {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 100;
  top: 82px;
  right: 20px;
  width: 150px;
  cursor: pointer;
  -webkit-transform: translate3d(0px, 118px, 0);
          transform: translate3d(0px, 118px, 0);
  -webkit-transition: -webkit-transform .5s ease-in-out;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
  /*	animation: slide-in 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	animation-delay: 1s;
	animation-fill-mode: forwards;*/ }

.draw-button {
  height: 42px; }

.draw-button--open {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  height: auto; }
  .draw-button--open .color-picker {
    opacity: 1; }

.color-picker {
  opacity: 0;
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease; }

.close-icon {
  width: 21px; }

@-webkit-keyframes slide-in {
  from {
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slide-in {
  from {
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.draw-image {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

iframe {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  /*will-change: blur;*/
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

#root {
  width: 100%;
  height: 100%; }

.App {
  width: 100%;
  height: 100%;
  -webkit-perspective: 140px;
          perspective: 140px; }

.iframe--blur {
  /*filter: blur(5px);*/
  -webkit-transform: translate3d(0, 0, 0) rotateX(62deg) scale(2);
          transform: translate3d(0, 0, 0) rotateX(62deg) scale(2); }

.grid {
  width: 600px;
  height: calc(100% - 40px);
  overflow: scroll;
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  background: #fff;
  box-shadow: 10px 10px grey;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px; }

.grid-row {
  display: -webkit-flex;
  display: flex; }

.grid-cell {
  border-bottom: .5px solid #aaa;
  border-right: 1px solid #aaa;
  /*border: 1px solid #aaa;*/
  width: 100px;
  height: 10px;
  display: block;
  cursor: crosshair; }
  .grid-cell:last-of-type {
    border-right: 0; }

/*
.color-picker {
	position: fixed;
	top: 14px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	background: #fff;
	left: 640px;
	width: 200px;
	border-radius: 3px;
}*/
.color-picker {
  background: #fff;
  /*width: 200px;*/ }

.color-header, .draw-header {
  -webkit-transition: background .5s ease;
  transition: background .5s ease;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 2px solid black; }

.draw-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-radius: 3px;
  overflow: hidden; }

.draw-header {
  background: white; }

.draw-button--open .draw-header {
  background: #EFEFEF;
  border-bottom: 2px solid black; }

.draw-header {
  border-bottom: none; }

.draw-header {
  border-bottom: 2px solid transparent h1;
    border-bottom-font-size: 32px;
    border-bottom-line-height: 1;
    border-bottom-margin-left: 10px; }

.color-picker {
  padding-top: 10px; }

.color-swatch {
  height: 15px;
  width: 55px;
  margin: 10px;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 1px;
  -webkit-transition: box-shadow .1s ease-in-out;
  transition: box-shadow .1s ease-in-out;
  cursor: pointer; }

.color-swatch--active {
  /*border: 1px solid black;*/
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24); }

.btn-grid .grid-cell {
  width: 50px; }

.r {
  background: red; }

.g {
  background: green; }

.b {
  background: blue; }

.grid-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  border-bottom: 2px solid black;
  width: 100%;
  -webkit-align-items: baseline;
          align-items: baseline;
  background: #DDFF28;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .grid-header h1 {
    font-weight: 300;
    font-size: 32px;
    line-height: 1;
    font-family: "Roboto Mono", monospace;
    margin: 0;
    margin-left: 10px; }

.grid__inner {
  /*margin: 20px;*/
  /*	border-left: 1px solid #aaa;
	border-top: 1px solid #aaa;*/ }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /*height: 42px;*/ }

.icon-wrap {
  height: 40px;
  width: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  height: 42px; }

.spreadsheet-icon {
  direction: ltr;
  text-align: left;
  height: 40px;
  overflow: hidden;
  vertical-align: middle;
  width: 40px;
  position: relative;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  /*transform-origin: bottom;*/ }
  .spreadsheet-icon span {
    position: absolute;
    top: 0;
    left: -20px; }
    .spreadsheet-icon span:after {
      content: url(//ssl.gstatic.com/docs/common/material_common_sprite87.svg); }

.color-icon {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  -webkit-transform-origin: center;
          transform-origin: center;
  position: relative;
  top: 4px; }
  .color-icon span {
    height: 2130px;
    position: absolute;
    width: 118px;
    left: -18px;
    top: -1538px;
    overflow: hidden;
    /*position: absolute;
		top: 0;
		left: -20px;*/ }
    .color-icon span:after {
      content: url(//ssl.gstatic.com/docs/common/material_common_sprite87.svg); }

.draw-icon {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  -webkit-transform: scale(1.52);
  transform: scale(1.52);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  top: 3px; }
  .draw-icon span {
    height: 2130px;
    position: absolute;
    width: 118px;
    left: 0px;
    top: 0px;
    overflow: hidden;
    /*position: absolute;
		top: 0;
		left: -20px;*/ }
    .draw-icon span:after {
      content: url(//ssl.gstatic.com/docs/common/material_common_sprite87.svg); }

.r-0, .r-8, .r-16 {
  background: white !important;
  cursor: default !important;
  pointer-events: none;
  border-right: 1px solid white !important; }

.r-16.c-3, .r-16.c-4, .r-16.c-5 {
  border-bottom: 1px solid white !important; }

.grid-row:nth-of-type(14n + 18) .c-3, .grid-row:nth-of-type(14n + 18) .c-4, .grid-row:nth-of-type(14n + 18) .c-5,
.grid-row:nth-of-type(14n + 19) .c-3,
.grid-row:nth-of-type(14n + 19) .c-4,
.grid-row:nth-of-type(14n + 19) .c-5,
.grid-row:nth-of-type(14n + 20) .c-3,
.grid-row:nth-of-type(14n + 20) .c-4,
.grid-row:nth-of-type(14n + 20) .c-5,
.grid-row:nth-of-type(14n + 21) .c-3,
.grid-row:nth-of-type(14n + 21) .c-4,
.grid-row:nth-of-type(14n + 21) .c-5,
.grid-row:nth-of-type(14n + 22) .c-3,
.grid-row:nth-of-type(14n + 22) .c-4,
.grid-row:nth-of-type(14n + 22) .c-5,
.grid-row:nth-of-type(14n + 23) .c-3,
.grid-row:nth-of-type(14n + 23) .c-4,
.grid-row:nth-of-type(14n + 23) .c-5,
.grid-row:nth-of-type(14n + 24) .c-3,
.grid-row:nth-of-type(14n + 24) .c-4,
.grid-row:nth-of-type(14n + 24) .c-5 {
  cursor: default !important;
  pointer-events: none;
  background: white !important;
  border-right: 1px solid white;
  border-bottom: 1px solid white; }

.grid-row:nth-of-type(14n + 25) .c-3, .grid-row:nth-of-type(14n + 25) .c-4, .grid-row:nth-of-type(14n + 25) .c-5 {
  cursor: default !important;
  pointer-events: none;
  background: white !important;
  border-right: 1px solid white; }

.grid-row:nth-of-type(14n + 18) .c-3, .grid-row:nth-of-type(14n + 18) .c-4, .grid-row:nth-of-type(14n + 18) .c-5 {
  cursor: default !important;
  pointer-events: none;
  background: white !important;
  border-right: 1px solid white;
  border-top: 1px solid white; }

.r-0.c-0 {
  position: relative; }
  .r-0.c-0:after {
    content: "About";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 7px;
    font-weight: bold; }

.r-8.c-0 {
  position: relative; }
  .r-8.c-0:after {
    content: "Past";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 7px;
    font-weight: bold; }

.r-16.c-0 {
  position: relative; }
  .r-16.c-0:after {
    content: "Work";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 7px;
    font-weight: bold; }

@media (max-width: 977px) {
  .grid {
    -webkit-transform: none;
            transform: none;
    right: 190px;
    left: auto;
    left: initial; } }

@media (max-width: 810px) {
  .grid {
    width: calc(100% - 210px); } }

